body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #fff; */
  /* background: #dddddd;  */
  /* background: '#A9DAF0';  */
  /* background: '#58BBE7';  */
  background: #0a1624;
  background-image: radial-gradient(circle at right top, rgba(20,120,144,1) 0%, rgba(11,29,43,1) 100%);
  background-size: 100% 100%;

  background-repeat: no-repeat;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
